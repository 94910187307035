import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackButton, Heading, Button, Modal } from "../../components/Common";
import { OrderDetail } from "../../Service/Orders.service";
import { useLocation } from "react-router-dom";
import moment from "moment";
import html2pdf from 'html2pdf.js'
import LOGO from '../../assets/images/Logoo.png'
import { status } from "../../helper/Helper";


const OrderDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const userId = localStorage.getItem("USERID");
  const role = localStorage.getItem("ROLE");
  const { state } = useLocation();
  const [order, setOrder] = React.useState({});
  useEffect(() => {
    if (state && state?.id) {
      purchaseOrderData(state.id);
    }
  }, []);

  const purchaseOrderData = async (id) => {
    const res = await OrderDetail(id);
    if (res?.status) {
      setOrder(res?.data);
    } else {
      setOrder({});
    }
  };
        console.log("Order", order.id)

  const closeFlagHandler = () => {
    purchaseOrderData(state.id);
  };

  const handleDownload = () => {
    const data = 
`<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title></title>
    <style>
        @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

        body {
            font-family: "Montserrat", sans-serif !important;
        }

        .container {
            margin: 0 auto;
            max-width: 210mm;
            height: 275mm;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding: 70px 45px !important;
            position: relative;
            overflow: hidden;
        }

        .content {
            display: flex;
            justify-content: space-between;
        }

        .content-left {
            margin-top: 10px;
            width: 50%;
            display: flex;
            flex-direction: column;
            /* / justify-content: space-between; / */
            gap: 30px;
        }

        .description {
            margin-top: 50px;
        }

        .content-right {
            width: 50%;
        }

        img {
            width: 100%;
            max-width: 250px;
        }

        h1 {
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            margin: 0;
        }

        h2 {
            font-size: 25px;
            font-weight: 700;
            line-height: 33px;
            margin: 0;
        }

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
        }

        .top-right {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .date-info,
        .left,
        .right {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
        }

        .date-info p,
        .left p,
        .right p {
            font-size: 12px;
            font-weight: 500;
        }

        p {
            font-size: 11px;
            font-weight: 500;
            margin: 0;
            line-height: 22px;
        }

        hr {
            border: 1px solid rgba(0, 0, 0, 0.32);
            margin: 3px 0;
        }


        .table {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
        }

        .col-desc,
        .col-taken,
        .col-number,
        .col-discount,
        .col-mva,
        .col-amount {
            text-align: center;
            padding: 6px;
            font-weight: 700;
        }

        .col-desc {
            text-align: left;
            font-weight: 700;
        }

        .table_data {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400 !important;
        }

        .col-desc_det {
            text-align: start;
            padding: 6px;
            font-weight: 400;
        }

        .col-taken_det,
        .col-number_det,
        .col-discount_det,
        .col-mva_det,
        .col-amount_det {
            text-align: center;
            padding: 6px;
            font-weight: 400;
        }

        .col-amount,
        .col-amount_det {
            text-align: end;
        }

        .eu-control {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr;
            font-size: 12px;
        }

        .left,
        .center,
        .right {
            text-align: center;
        }

        .table-summary {
            display: grid;
            grid-template-columns: 2fr 2fr;
            font-size: 12px;
        }

        .to-pay {
            display: flex;
            justify-content: space-between;
            padding: 6px;
        }

        .to-pay p {
            font-size: 14px;
            font-weight: 700;
        }

        .payment-section {
            padding: 0px;
        }

        .cash-invoice {
            font-size: 12px;
            font-weight: 500;
        }

        .payment-info {
            display: flex;
            align-items: center;
            margin-top: 0px;
        }

        h2 {
            font-size: 20px;
            font-weight: 700;
            margin-right: 10px;
        }

        .blue-line {
            width: 100%;
            height: 15px;
            background: #ffc107;
        }

        .payment-details {
            display: flex;
            flex-direction: column;
            margin: 12px 0;
        }

        .payment-detail {
            display: flex;
            justify-content: flex-start;
        }

        .detail-label {
            font-size: 15px;
            font-weight: 500;
            width: 170px;
        }

        .detail-value {
            font-size: 15px;
            font-weight: 500;
        }

        .divider {
            border: 1px solid #000;
        }

        .company-info {
            font-size: 11.5px;
            font-weight: 500;
            margin-top: 0px;
        }

        .bolder {
            font-weight: 700;
        }

        .eu_control_div {
            padding: 3px 6px;
            text-align: center;
        }

        .eu_control_div p {
            line-height: 20px;
        }

        .eu_control_div:first-child {
            text-align: start;
        }

        .eu_control_div:last-child {
            text-align: end;
        }

        .table-summary_left {
            padding: 0 6px;
            line-height: 20px !important;
        }

        .table-summary_right {
            text-align: end;
            padding: 0 6px;
            line-height: 20px !important;
        }

        .table-summary_left p,
        .table-summary_right p {
            margin-top: 5px;
        }

        .eu-control p,
        .table-summary p {
            font-size: 12px;
            line-height: 18px;
        }

        .eu-control,
        .table-summary {
            padding: 5px 0;
        }

        table {
            border: 1px solid #000;
        }

        td {
            border-top: 1px solid #000;
            border-right: 1px solid #000;

        }

        th {
            border-right: 1px solid #000;

        }

        tr th,
        tr td {
            text-align: center;
            padding: 7px 1px;
            font-size: 11px;
        }

        tr th:first-child,
        tr td:first-child {
            text-align: left;
        }

        tr th:last-child,
        tr td:last-child {
            text-align: right;
            border-right: 0px solid #000;

        }
    </style>
</head>

<body>
    <div class="container">
        <div>
            <div class="content">
                <div style="width: 100%;">
                    <div style="display: flex; justify-content: space-between;width: 100%;">
                        <div>
                            <img src=${LOGO} width="250" alt="Company Logo" />
                        </div>
                        <div>
                            <p style="font-size: 12px; font-weight: 700">Tax invoice/cash memo/Bill of supply</p>
                        </div>

                    </div>
                    <div style="margin-top: 50px; display: flex;justify-content:space-between">
                        <div style="width: 50%">
                            <div style="display: flex; gap: 8px; ">
                                <p style="font-weight: bold; width: 140px;">Service Order number:</p>
                                <p>${order?.orderNumber}</p>
                            </div>
                            <div style="display: flex; gap: 8px; ">
                                <p style="font-weight: bold; width: 140px;">Service date & time:</p>
                                <p>${order?.date} ${order?.time}</p>
                            </div>
                        </div>
                        <div style="width: 50%;display: flex; flex-direction: column; align-items: end; ">
                            <div style="display: flex; gap: 8px;">
                                <p style="font-weight: bold;">Invoice number:</p>
                                <p>${order?.id}</p>
                            </div>
                            <div style="display: flex; gap: 8px;">
                                <p style="font-weight: bold;">Invoice Date:</p>
                                <p>${order?.date}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 50px; display: flex;justify-content:space-between;gap: 10px;">
                <div style="width: 50%">
                    <div style="display: flex; gap: 3px; flex-direction: column;">
                        <p style="font-weight: bold;">Service supply by: ${order?.providerId?.firstName} ${order?.providerId?.lastName}</p>
                        <p style="width: auto;">${order?.providerAderess?.address}, ${order?.providerAderess?.state}, ${order?.providerAderess?.city}, ${order?.providerAderess?.pincode}</p>
                    </div>
                    <div style="display: flex; gap: 8px; flex-direction: row; margin-top: 10px;">
                        <p style="font-weight: bold;">Pan no:</p>
                        <p>${order?.providerId?.panNo || '-'}</p>
                    </div>
                    <div style="display: flex; gap: 8px; flex-direction: row;">
                        <p style="font-weight: bold;">GST no:</p>
                        <p>${order?.providerId?.GSTNo || '-'}</p>
                    </div>
                </div>
                <div style="width: 50%; display: flex; flex-direction: column; align-items: end;">
                    <div style="display: flex; gap: 3px; flex-direction: column; ">
                        <p style="font-weight: bold; text-align: end;">Billing Address:</p>
                        <p style="width: auto;">${order?.userAddress?.address}, ${order?.userAddress?.state}, ${order?.userAddress?.city}, ${order?.userAddress?.pincode}</p>
                    </div>
                    <div style="display: flex; gap: 3px; flex-direction: column; ">
                        <p style="font-weight: bold; text-align: end;">Service Delivery address:</p>
                        <p style="width: auto; ">${order?.userAddress?.address}, ${order?.userAddress?.state}, ${order?.userAddress?.city}, ${order?.userAddress?.pincode}</p>
                    </div>
                    <div style="display: flex; gap: 8px; flex-direction: row;">
                        <p style="font-weight: bold;">Place of supply:</p>
                        <p>${order?.userAddress?.landmark}</p>
                    </div>
                </div>
            </div>
            <div class="description">
                <table style="width:100%">
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Description</th>
                            <th>Cost <br />(inclusive of tax)</th>
                            <th>Discount</th>
                            <th>GST</th>
                            <th>Net amount</th>
                            <th>Tax amount</th>
                            <th>Platform Fee</th>
                            <th>Total amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>${order?.serviceId?.name}</td>
                            <td>${order?.serviceId?.cost}</td>
                            <td>0</td>
                            <td>${order?.taxData?.lable}</td>
                            <td>Rs.${order?.subTotal}</td>
                            <td>Rs.${order?.tax}</td>
                            <td>Rs.${order?.plateformFee}</td>
                            <td>Rs.${order?.totalAmount}</td>
                        </tr>
                        <!-- final row -->
                        <tr>
                            <td></td>
                            <td></td>
                            <td colspan="6" style="text-align: start;">Total</td>
                            <td>Rs.${order?.totalAmount}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <p style="font-size: 8px; margin-left: 10px;">Whether the tax is payable under reverse charge-no</p>
                </div>
                <!-- <div style="display: flex; justify-content: space-between">
                    <div class="" style="width: 43%">
                    </div>
                    <div style="width: 50%; margin-top: 10px;">
                        <div class="table-summary">
                            <div class="table-summary_left">
                                <p>Subtotal</p>
                                <p>CGST 9%</p>
                                <p>SGST 9%</p>
                            </div>
                            <div class="table-summary_right">
                                <p>&#8377; 10</p>
                                <p>&#8377; 10</p>
                                <p>&#8377; 10</p>
                            </div>
                        </div>
                        <hr style="border:1px solid #000" />
                        <div class="to-pay">
                            <p>Payable Amount</p>
                            <p>&#8377; 10</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div>
            <div style="display: flex; flex-direction: column; justify-content: center;">
                <p style="font-size: 8px; text-align: center;">For support, email urbeehelpcentre@gmail.com.*Prices
                    include/exclude GST. GSTIN: 33AADCU5788R1ZE.*We
                    protect your privacy. See our privacy policy on our website.*The app connects users and service
                    providers, so the app is not responsible for mishappens.*it includes platform commissions*See
                    our
                    website for terms, privacy, and refund policies. </p>
                <a href="http://www.urbee.in" style="text-align: center; font-size: 8px;" target="_blank">www.urbee.in</a>
            </div>
        </div>
    </div>


    </div>
</body>

</html>`;

    html2pdf()
      .set({
        filename: 'Invoice' + '.pdf',
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 4, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .from(data)
      .save()
  }

  return (
    <Box sx={{ maxWidth: "1200px" }}>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={1}
        alignItems={"center"}
        mb={1}
      >
        <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
          <BackButton />
          <Heading head="Order Details" />
        </Stack>
        <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
          {(role == 1 ) && (
            <Button
              buttonName="Cancel Order"
              size="small"
              onClick={() =>
                setIsModalOpen({
                  open: true,
                  currentComponent: "ORDERCANCEL",
                  id: order.id,
                })
              }
            />
          )}
          <Button
            buttonName="Download Invoice"
            size="small"
            onClick={() => handleDownload()}
          />
          {(order?.providerId?.id == userId && order?.status == 2) && (
            <Button
              buttonName="Service Start OTP"
              size="small"
              onClick={() =>
                setIsModalOpen({
                  open: true,
                  currentComponent: "OTPSTARTSEND",
                  id: order.id,
                })
              }
            />
          )}

          {(order?.providerId?.id == userId && order?.status == 5) && (
            <Button
              buttonName="Service End OTP"
              size="small"
              onClick={() =>
                setIsModalOpen({
                  open: true,
                  currentComponent: "OTPENDSEND",
                  id: order.id,
                })
              }
            />
          )}
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="card_container" p={{ xs: "20px", sm: 4 }}>
            <Stack
              mb={2}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography fontSize={"20px"} fontWeight={"600"}>
                Customer Details
              </Typography>
            </Stack>
            <Stack gap={{ xs: 2, md: 8 }} flexDirection={{ md: "row" }}>
              <Stack gap={"4px"}>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Name :</span> {order?.name}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Email :</span>{" "}
                  {order?.userId?.email}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Mobile :</span>{" "}
                  {order?.userId?.mobileNo}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Date :</span>{" "}
                  {moment(order?.date).format("D MMMM YYYY")}, at {order?.time}
                </Typography>
              </Stack>
              <Stack gap={"4px"}>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Address :</span>{" "}
                  {order?.address}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>City :</span> {order?.city}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>State :</span>{" "}
                  {order?.state}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 500 }}>Pincode :</span>{" "}
                  {order?.pincode}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="card_container" p={{ xs: "20px", sm: 4 }}>
            <Stack
              mb={2}
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={"space-between"}

            >
              <Typography fontSize={"20px"} fontWeight={"600"}>
                Order Details
              </Typography>
              {(order?.reason) && (
                <Typography fontSize={"15px"} fontWeight={"600"}>
                  Reason for cancelled order - {order?.reason}
                </Typography>
              )}
            </Stack>
            {/* <TableContainer component={Paper}> */}
            <div
              className="order_details_table"
              style={{
                overflow: "auto",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                marginBottom: "24px",
              }}
            >
              <Table stickyHeader aria-label="simple table" sx={{}}>
                <TableHead>
                  <TableRow>
                    <TableCell> Service </TableCell>
                    <TableCell> Category </TableCell>
                    <TableCell> Provider </TableCell>
                    <TableCell> Cost </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell> {order?.serviceId?.name} </TableCell>
                    <TableCell>
                      {" "}
                      {order?.serviceId?.categoryId?.name}{" "}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {order?.providerId?.firstName}{" "}
                      {order?.providerId?.lastName}{" "}
                      - ({order?.providerId?.mobileNo})
                    </TableCell>
                    <TableCell> &#8377; {order?.serviceId?.cost} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            {/* </TableContainer> */}

            <Stack sx={{ alignItems: "flex-end", mr: { sm: 2 } }} gap={1}>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={{ xs: "100%", sm: "300px" }}
              >
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  Subtotal
                </Typography>
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  &#8377; {order?.subTotal}
                </Typography>
              </Stack>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={{ xs: "100%", sm: "300px" }}
              >
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  {order?.taxData?.lable}
                </Typography>
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  &#8377; {order?.tax}
                </Typography>
              </Stack>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={{ xs: "100%", sm: "300px" }}
              >
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  Platform fee
                </Typography>
                <Typography
                  fontSize={{ xs: "13px", sm: "16px" }}
                  fontWeight={"500"}
                  color={"#999"}
                >
                  &#8377; {order?.platformFee}
                </Typography>
              </Stack>
              <Divider
                width={{ xs: "-webkit-fill-available", sm: "300px" }}
                sx={{ borderWidth: "1px", borderColor: "#999" }}
              />
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={{ xs: "100%", sm: "300px" }}
              >
                <Typography fontWeight={"500"} fontSize={{ sm: "20px" }}>
                  Payable Amount
                </Typography>
                <Typography fontWeight={"500"} fontSize={{ sm: "20px" }}>
                  &#8377; {order?.totalAmount}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          purchaseOrderData(state.id);

          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        }
        closeFlag={closeFlagHandler}
      />
    </Box>
  );
};

export default OrderDetails;
