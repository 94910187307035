import React, { useState, useEffect } from "react";
import {
    DialogActions,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CommonButton from "../Button";

const OrderDownload = ({ handleClose, values, search }) => {
    return (
        <>
            <div>
                <form >
                    <DialogTitle sx={{ m: 2, p: 3 }} id="Common_modal">
                        {"Do you want to download this file?"}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogActions>
                        <CommonButton
                            buttonName="Yes"
                            onClick={(e) => {
                                e.preventDefault();
                                // const queryParams = Object.entries(values).map(([key, value]) => `${key}=${value}`).join('&');
                                //  const searchString = search ? `search=${search}` : '';
                                window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/exportOrders`;

                                handleClose()
                            }}
                        />

                        <CommonButton buttonName="Cancel" onClick={handleClose} />

                    </DialogActions>
                </form>
            </div>

        </>
    );

};

export default OrderDownload;
