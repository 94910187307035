import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "..";
import { CancelBookingByAdmin } from "../../../Service/Orders.service";
import { errorNotification, successNotification } from "../Notification";

function CancelOrder({ handleClose, modalOpen, handelClick, closeFlag }) {
  console.log("ModalOpen", modalOpen);

  const agreeHandler = async () => {
      const { data, message, status } = await CancelBookingByAdmin(
        modalOpen.id
      );
      if (status) {
         handleClose();
        successNotification(message);
      } else {
        handleClose();
        errorNotification(message);
      }
    }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Are you sure you want to cancel this order?
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography fontSize={"18px"}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Yes" size="small" onClick={() => agreeHandler()} />
      </DialogActions>
    </>
  );
}

export default CancelOrder;
