import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BackButton,
  Button,
  FileUpload,
  Heading,
  Input,
} from "../../components/Common";
import { Modal } from "../../components/Common";
import { useFormik } from "formik";
import { serviceIv } from "../../helper/initialValues";
import { addServiceValidation } from "../../helper/validation";
import { listCategoryHandler } from "../../Service/category.service";
import { listTaxHandler } from "../../Service/tax.service.js";
import {
  EditServiceApiHandler,
  addServiceApiHandler,
  viewServiceApiHandler,
  getPlatformFeeApiHandler
} from "../../Service/Services.service";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon } from "../../helper/Icons";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";
import NoRecordFound from "../../components/Common/Table/NoRecordFound";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { MdDeleteOutline } from "react-icons/md";
import { GiRoundStar } from "react-icons/gi";

// import { PROVIDER } from "../../helper/Helper";
// console.log('PROVIDER ===========', PROVIDER);
function AddService({ Componentstatus, name }) {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteImage, setDeleteImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [taxLoader, setTaxLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [taxList, setTaxList] = useState([]);
  const [selectTax, setSelectTax] = useState("");
  const [machineryList, setMachineryList] = useState([]);
  const [review, setReview] = useState([]);
  const [id, setId] = useState("");
  const [platformFee, setplatformFee] = useState("");
  const [machineText, setMachineText] = useState("");
  const [imageindex, setImageIndex] = React.useState(0);
  console.log("state", state, id);
  const currRole = localStorage.getItem("ROLE");
  const addServiceHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", formik.values.name);
      formData.append("categoryId", formik.values.categoryId);
      formData.append("cost", formik.values.cost);
      formData.append("tax", formik.values.tax);
      // formData.append("benefits", formik.values.benefits);
      formData.append("description", formik.values.description);
      formData.append("longDescription", formik.values.longDescription);
      formData.append("machinery", machineryList);

      files.forEach((file) => {
        formData.append("image", file);
      });

      const { data, message, status } =
        Componentstatus === "EDIT"
          ? await EditServiceApiHandler(state?.id || id, formData)
          : await addServiceApiHandler(formData);
      if (status) {
        navigate(`/services`);
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: serviceIv,
    validationSchema: addServiceValidation,
    onSubmit: addServiceHandler,
  });

  useEffect(() => {
    getAllCategoryHandler();
  }, []);

  useEffect(() => {
    getAllTaxHandler();
  }, []);

  useEffect(() => {
    if (Componentstatus !== "ADD") {
      getServiceDataHandler(state?.id || id);
    }
  }, [Componentstatus]);

  useEffect(() => {
    getPlatformfeeDataHandler()
  }, []);

  const getServiceDataHandler = async (id) => {
    const res = await viewServiceApiHandler(id);
    if (res?.status) {
      formik.setValues({
        ...formik.values,
        name: res?.data?.name,
        categoryId: res?.data?.categoryId?.id,
        cost: res?.data?.cost,
        tax: res?.data?.tax?.id,
        // benefits: res?.data?.benefits,
        description: res?.data.description,
        longDescription: res?.data?.longDescription,
        // image: res?.data?.image,
        machinery: res?.data?.machinery && res?.data?.machinery.split(","),
      });
      setSelectCategory(res?.data?.categoryId?.id);
      setSelectTax(res?.data?.tax?.id);
      setReview(res?.data?.review);
      setplatformFee(res?.data?.platformfee)
      setFiles(res?.data?.serviceImage || []);
      setId(res?.data?.id)
      const machineData =
        res?.data?.machinery && res?.data?.machinery.split(",");
      setMachineryList(machineData);
    } else {
      setTaxLoader(false);
    }
  };
  const getPlatformfeeDataHandler = async () => {
    const res = await getPlatformFeeApiHandler();
    if (res?.status) {
      setplatformFee(res?.data?.taxValue)
    } else {
      setplatformFee('');
    }
  };
  const categoreySelectHandler = (value) => {
    setSelectCategory(value);
    formik.setFieldValue("categoryId", value);
  };

  const taxSelectHandler = (value) => {
    setSelectTax(value);
    formik.setFieldValue("tax", value);
  };

  const fileUploadHandler = (data) => {
    setFiles(data);
    formik.setFieldValue("image", data);
  };

  const machineTextHandler = (event) => {
    if (event.keyCode === 13 && machineText.trim() !== "") {
      setMachineryList([machineText, ...machineryList]);
      setMachineText("");
      formik.setFieldValue("machinery", [machineText, ...machineryList]);
    }
  };

  const deleteMachinTextHandler = (index) => {
    const newMacinTextValue = [...machineryList];
    newMacinTextValue.splice(index, 1);
    setMachineryList(newMacinTextValue);
    formik.setFieldValue("machinery", newMacinTextValue);
  };

  const getAllCategoryHandler = async () => {
    try {
      setCategoryLoader(true);
      const { data, message, status, total } = await listCategoryHandler();
      if (status) {
        setCategoryList(data);
      } else {
        setCategoryList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCategoryLoader(false);
    }
  };



  const getAllTaxHandler = async () => {
    try {
      setTaxLoader(true);
      const { data, message, status, total } = await listTaxHandler();

      if (status) {
        setTaxList(data);
        configTaxOnProvider();
      } else {
        setTaxList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTaxLoader(false);
    }
  };

  const configTaxOnProvider = async () => {
    console.log('calling___');
    if (currRole == 2) {
      console.log('Inside______');
      formik.setFieldValue("tax", 4)
      setSelectTax(4)
    }
  }
  const closeFlagHandler = () => {
    getServiceDataHandler(state?.id || id);
  };

  const deleteProviderHandler = async (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "Delete",
      para: "Are you sure want to remove this review ?",
      head: "Remove review",
      id: id,
      name: "Review",
    });
  };
  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={1}
      >
        <BackButton />
        <Heading head={`${name} Service`} />
        {Componentstatus === "VIEW" && (
          <Button
            buttonName={"Edit"}
            size="small"
            onClick={() =>
              navigate(`/edit-services`, {
                id: id,
                data: state?.data,
              })
            }
          >
            Edit
          </Button>
        )}
      </Stack>
      <Box className="card_container">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Input
                labelinput="Service Name"
                formik={formik}
                name="name"
                disabled={Componentstatus === "VIEW"}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontSize={{ xs: "13px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
              >
                {`Category`}
              </Typography>
              <Select
                displayEmpty
                disabled={Componentstatus === "VIEW"}
                value={selectCategory ? selectCategory : ""}
                error={
                  formik.touched["categoryId"] && formik.errors["categoryId"]
                }
                onChange={(event) => categoreySelectHandler(event.target.value)}
                fullWidth
              >
                {categoryList && categoryList.length > 0 ? (
                  categoryList.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
              {formik.touched["categoryId"] && (
                <FormHelperText sx={{ color: "red !important" }}>
                  {formik.errors.categoryId}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Cost per session"
                    formik={formik}
                    disabled={Componentstatus === "VIEW"}
                    name="cost"
                    type="number"
                    maxLength={10}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Additional Information "
                    formik={formik}
                    name="benefits"
                    disabled={Componentstatus === "VIEW"}
                    maxLength={200}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Description"
                    formik={formik}
                    disabled={Componentstatus === "VIEW"}
                    name="description"
                    maxLength={200}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Per session"
                    formik={formik}
                    disabled={Componentstatus === "VIEW"}
                    name="longDescription"
                    maxLength={200}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Machinery"
                    onChange={(event) => setMachineText(event.target.value)}
                    onKeyDown={(event) => machineTextHandler(event)}
                    // formik={formik}
                    value={machineText}
                    disabled={Componentstatus === "VIEW"}
                    name="machinery"
                    maxLength={25}
                  />

                  <Typography sx={{ color: "#f3263a", fontSize: "12px" }}>
                    Write each machinery and press enter.
                  </Typography>
                  {machineryList && machineryList.length > 0 ? (
                    <Stack
                      flexDirection={"row"}
                      flexWrap={"wrap"}
                      gap={1}
                      mt={1}
                    >
                      {machineryList.map((item, index) => {
                        return (
                          <Box
                            sx={{
                              border: "1px solid #d8d8d8",
                              width: "max-content",
                              padding:
                                Componentstatus !== "VIEW"
                                  ? "0 0 0 10px"
                                  : "0 10px",
                              borderRadius: "4px",
                            }}
                          >
                            {item}
                            {Componentstatus !== "VIEW" && (
                              <CloseIcon
                                sx={{
                                  color: "#fff",
                                  background: "#d8d8d8",
                                  width: "26px",
                                  height: "26px",
                                  marginLeft: "10px",
                                }}
                                onClick={() => deleteMachinTextHandler(index)}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    fontSize={{ xs: "13px", sm: "16px" }}
                    fontWeight={500}
                    mb={"2px"}
                  >
                    {`Tax`}
                  </Typography>

                  <Select
                    displayEmpty
                    // disabled={Componentstatus === "VIEW" ? currRole == 2 ? false : true : false}
                    // disabled={currRole == 2 ? true : Componentstatus === "VIEW"}
                    value={selectTax ? selectTax : ""}
                    error={formik.touched["tax"] && formik.errors["tax"]}
                    onChange={(event) => taxSelectHandler(event.target.value)}
                    fullWidth
                  >
                    {taxList && taxList.length > 0 ? (
                      taxList.map((response, index) => {
                        return (
                          <MenuItem value={response.id}>
                            {response.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Select>
                  {formik.touched["tax"] && (
                    <FormHelperText sx={{ color: "red !important" }}>
                      {formik.errors.tax}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {Componentstatus !== "VIEW" ? (
              <Grid item xs={12}>
                <FileUpload
                  FileType="Image"
                  acceptFormat="image/*"
                  files={files}
                  disabled={Componentstatus === "VIEW"}
                  setFiles={setFiles}
                  fileUploadHandler={fileUploadHandler}
                  setDeleteImage={setDeleteImage}
                  deleteImage={deleteImage}
                />
                {formik.touched["image"] && formik.errors["image"] && (
                  <FormHelperText sx={{ color: "red !important" }}>
                    {formik.errors.image}
                  </FormHelperText>
                )}
              </Grid>
            ) : (
              <Grid item xs={5}>
                <img
                  src={files && files[imageindex]?.image}
                  className="box_shadow"
                  alt={""}
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    marginBottom: "10px",
                  }}
                />
                <Grid container item spacing={"10px"}>
                  {files &&
                    files.map((item, i) => {
                      return (
                        <Grid item xs={3} onClick={() => setImageIndex(i)}>
                          <img
                            key={i}
                            className="box_shadow"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={item.image}
                            alt={item.id}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              {currRole == 2 ? (<Typography
                fontSize={{ xs: "13px", sm: "14px", mt: "0px" }}
                fontWeight={500}
                mb={"2px"}
                color={'red'}
                style={{ color: "red", marginTop: "-9px" }}
              >
                {`*${platformFee}% of platform fee will be applicable`}
              </Typography>) : ""}

            </Grid>
            {Componentstatus === "VIEW" && (
              <>
                <Typography fontSize={"20px"} fontWeight={"600"} mt={2}>
                  User Review
                </Typography>
                <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                  <div
                    className="order_details_table"
                    style={{
                      overflow: "auto",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      marginBottom: "24px",
                    }}
                  >
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Username</TableCell>
                          <TableCell>Order Number</TableCell>
                          <TableCell>Review</TableCell>
                          <TableCell>Average</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRowsLoader rowsNum={10} />
                        ) : review && review.length > 0 ? (
                          review.map((response, i) => {
                            const { id, orderNumber, fullname, average, comment } = response;
                            return (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell>{fullname || '-'}</TableCell>
                                <TableCell>{orderNumber || '-'}</TableCell>
                                <TableCell>{comment || '-'}</TableCell>
                                <TableCell sx={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>{average || '-'}
                                  <Box >{average && <GiRoundStar style={{ color: '#FFCC00' }} />}</Box>
                                </TableCell>
                                <TableCell >
                                  <MdDeleteOutline
                                    onClick={() => deleteProviderHandler(id)}
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <NoRecordFound rowsNum={5} />
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              </>
            )}

            <Grid item xs={12}>
              {Componentstatus !== "VIEW" && (
                <Button
                  buttonName={"Save"}
                  size="small"
                  loading={loading}
                  disabled={loading}
                  onClick={formik.handleSubmit}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "", id: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default AddService;
