import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./components/Common/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Loader from "./components/Common/Loader/Loader";
import { useLocation } from "react-router-dom";
import { loadUser } from "./Service/auth.service";
import PrivateRoute from "./Routes/PrivateRoute";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import { Modal } from "../src/components/Common";
import { User } from "./helper/Constant";
import Providers from "./pages/Providers";
import Category from "./pages/Category";
import Tax from "./pages/Tax";
import Services from "./pages/Services";
import AddService from "./pages/Services/AddService";
import Orders from "./pages/Order";
import OrderDetails from "./pages/Order/OrderDetails";
import ProviderLogin from "./pages/ProviderLogin";
import { PROVIDER, SUPERADMIN } from "./helper/Helper";
import PageNotFound from "./pages/404Page";
import Profile from "./pages/Profile";
import ProfileProvider from "./pages/Profile/providerProfile.jsx";
import Banner from "./pages/Banner";
import ProviderDetails from "./pages/Providers/ProviderDetails";
import { Navigate } from "react-router-dom";


const Login = React.lazy(() => import("./pages/Login/Login"));
const Users = React.lazy(() => import("./pages/Users"));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));

const pages = [
  {
    name: "dashboard",
    path: "dashboard",
    element: <Dashboard />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "users",
    path: "users",
    element: <Users />,
    permission: [SUPERADMIN],
  },
  {
    name: "providers",
    path: "providers",
    element: <Providers />,
    permission: [SUPERADMIN],
  },
  {
    name: "providers-details",
    path: "providers-details",
    element: <ProviderDetails />,
    permission: [SUPERADMIN],
  },
  {
    name: "category",
    path: "category",
    element: <Category />,
    permission: [SUPERADMIN],
  },
  {
    name: "tax",
    path: "tax",
    element: <Tax />,
    permission: [SUPERADMIN],
  },
  {
    name: "banner",
    path: "banner",
    element: <Banner />,
    permission: [SUPERADMIN],
  },
  {
    name: "services",
    path: "services",
    element: <Services />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "add Service",
    path: "add-services",
    element: <AddService Componentstatus="ADD" name="Add" />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "Edit Service",
    path: "edit-services",
    element: <AddService Componentstatus="EDIT" name="Edit" />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "view Service",
    path: "view-services",
    element: <AddService Componentstatus="VIEW" name="View" />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "orders",
    path: "orders",
    element: <Orders />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "view-order",
    path: "view-order",
    element: <OrderDetails />,
    permission: [SUPERADMIN, PROVIDER],
  },
  {
    name: "Profile",
    path: "service-profile",
    element: <ProfileProvider />,
    permission: [PROVIDER],
  },
  {
    name: "SA Profile",
    path: "profile",
    element: <Profile />,
    permission: [SUPERADMIN],
  },
];

function App() {
  const { auth } = useSelector((state) => state?.root);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: "Logged out due to inactivity. Please log in again to continue.",
        head: "Session Expired",
      });
    }
  }, [auth]);

  const role = localStorage.getItem("ROLE");

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: "",
      head: "",
      id: "",
    });
    localStorage.removeItem("token");
    localStorage.removeItem("USERID");
    dispatch(logoutApi());

    if (role == SUPERADMIN) {
      navigate("/adminLogin");
    } else {
      navigate("/");
    }
    localStorage.removeItem("ROLE");
  };


  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <div className="background_color">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={!role ? <ProviderLogin /> : <Navigate to='/dashboard' />} />
                <Route path="/adminLogin" element={!role ? <Login /> : <Navigate to='/dashboard' />} />

                {pages?.map((page, index) => {
                  const { name, path, element, permission } = page;
                  return (
                    <Route
                      key={index}
                      exact
                      path={`/${path}`}
                      name={name}
                      element={
                        <PrivateRoute name={name} permissionStatus={permission}>
                          <Layout>{element}</Layout>
                        </PrivateRoute>
                      }
                    />
                  );
                })}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              <ToastContainer />
            </Suspense>
          </div>
          <Modal
            modalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleClose={() => {
              return;
            }}
            handelClick={handelLogout}
          />
        </ThemeProvider>
      </ScrollToTop>
    </>
  );
}

export default App;
