import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../../components/Common";
import { useFormik } from "formik";
import { providerLoginIv, sendOtpIv } from "../../helper/initialValues";
import {
  providerLoginValidationSchema,
  sendOtpValidationSchema,
} from "../../helper/validation";
import Cookies from "universal-cookie";
import {
  providerLoginApiHandler,
  sendOtpApiHandler,
} from "../../Service/auth.service";
import OtpInput from "react-otp-input";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";

function ProviderLogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpFlag, setOtpFlag] = useState(false);

  const cookies = new Cookies();

  const providerLoginHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        mobileNo: formik.values.mobileNo.toString(),
        otp: formik.values.otp.toString(),
      };
      const { data, message, status } = await providerLoginApiHandler(payload);
      if (status) {
        localStorage.setItem("token", data?.token);
        localStorage.setItem("ROLE", data?.role);
        localStorage.setItem("USERID", data?.id);
        localStorage.setItem(
          "FULLNAME",
          `${data?.firstName} ${data?.lastName}`
        );
        navigate(`/dashboard`);
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const sendLoginHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        mobileNo: formik.values.mobileNo.toString(),
      };
      const { data, message, status } = await sendOtpApiHandler(payload);
      if (status) {
        setOtpFlag(true);
        successNotification(message);
      } else {
        setOtpFlag(false);
        errorNotification(message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: otpFlag ? providerLoginIv : sendOtpIv,
    validationSchema: otpFlag
      ? providerLoginValidationSchema
      : sendOtpValidationSchema,
    onSubmit: otpFlag ? providerLoginHandler : sendLoginHandler,
  });



  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: "-webkit-fill-available", md: "100%" },
        height: "-webkit-fill-available",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", top: "10px", left: "20px" }}>
        {/* <img src={Logo} alt='logo' width={150} /> */}
      </Box>
      <Stack
        flexDirection={{ md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "100vh" }}
      >
        <Box
          width={{ xs: "94%", md: "45%" }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={{ xs: 4 }} width={{ xs: "100%", sm: "450px" }}>
            <Box>
              <Typography
                fontSize={{ xs: "20px", sm: "35px" }}
                fontWeight={"700"}
                textAlign={"center"}
                mb={2}
                className="Gilroy_ExtraBold"
              >
                Login
              </Typography>
              <Typography
                fontSize={{ xs: "17px", sm: "20px" }}
                fontWeight={"600"}
                textAlign={"center"}
              >
                Welcome back to <Link to={"#"}>Urbee</Link>
              </Typography>
            </Box>
            <Stack gap={{ xs: 2, sm: 3 }}>
              <Stack>
                <Input
                  placeholder="Mobile No"
                  labelinput="Mobile No"
                  name="mobileNo"
                  type="number"
                  formik={formik}
                  disabled={otpFlag}
                  maxLength={10}
                />
              </Stack>
              <div className="otp_input">
                {otpFlag && (
                  <OtpInput
                    value={otp}
                    disabled
                    onChange={(event) => [
                      setOtp(event),
                      formik.setFieldValue("otp", event),
                    ]}
                    numInputs={4}
                    inputType="number"
                    // renderSeparator={<span style={{ padding: '0 10px' }}>-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "60px",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "7px",
                          textAlign: "center",
                          padding: "8px 10px",
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <Box
                width={"fit-content"}
                margin={"auto"}
                className="disable_button"
              >
                <Button
                  buttonName={
                    loading ? (
                      <React.Fragment>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CircularProgress size={20} color="inherit" />{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </React.Fragment>
                    ) : (
                      "Sign In"
                    )
                  }
                  onClick={() => formik.handleSubmit()}
                  style={{ width: "100%" }}
                  disabled={loading}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default ProviderLogin;
